<template>
    <div class="article_list_content">
        <div class="article_list" v-if="article_count > 0">
            <div class="cate_article_item" v-for="item in articleList" :key="item._id" @click="toDetail(item)">
                <div class="c_a_img_box">
                    <img :src="item.pictrue" class="c_a_img" alt="">
                </div>
                <div class="c_a_rg column_start_between">
                    <div class="c_a_top">
                        <p class="c_a_title ellipsis">{{item.title}}</p>
                        <div class="c_a_content">{{item.meta_description}}</div>
                    </div>
                    <div class="c_a_info flex_start">
                        <div class="cate_tag" v-if="item.profile">{{item.profile}}</div>
                        <!-- <span class="c_info_date">{{item.add_time_text | dateText}}</span> -->
                        <!-- <div class="flex_center" style="margin-right:8px;" @click.stop="onZan(index)">
                            <svg t="1608637523049" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7007" width="16" height="16">
                                <path
                                    d="M884.875894 423.143253 646.970506 423.143253c92.185562-340.464205-63.516616-357.853247-63.516616-357.853247-65.993017 0-52.312436 52.182476-57.3031 60.881602 0 166.502152-176.849824 296.971645-176.849824 296.971645l0 472.171899c0 46.607504 63.516616 63.393819 88.433098 63.393819l357.452111 0c33.641191 0 61.036122-88.224344 61.036122-88.224344 88.434122-300.70569 88.434122-390.177444 88.434122-390.177444C944.657442 418.179195 884.875894 423.143253 884.875894 423.143253L884.875894 423.143253 884.875894 423.143253zM884.875894 423.143253M251.671415 423.299819 109.214912 423.299819c-29.420053 0-29.873378 28.89612-29.873378 28.89612l29.420053 476.202703c0 30.309306 30.361495 30.309306 30.361495 30.309306L262.420223 958.707948c25.686009 0 25.458835-20.049638 25.458835-20.049638L287.879058 459.411271C287.879058 422.837284 251.671415 423.299819 251.671415 423.299819L251.671415 423.299819 251.671415 423.299819zM251.671415 423.299819"
                                    p-id="7008"
                                    :fill="item.is_zan == 1?'#2478e3':'#afafaf'">
                                </path>
                            </svg>
                            <span class="c_a_num">{{item.zan}} 点赞</span>
                        </div>
                        <div class="flex_center" style="margin-right:8px;">
                            <svg t="1608704579089" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6487" width="16" height="16">
                                <path
                                    d="M867.265818 784.595468 681.177586 784.595468c-62.5895 60.688197-158.625764 169.570024-158.625764 169.570024-5.817496 6.058996-15.288195 6.058996-21.086248 0 0 0-131.200134-135.805012-162.894997-169.570024L156.752602 784.595468c-67.418482 0-122.045637-63.007009-122.045637-131.366933L34.706965 189.078333c0-68.358901 53.43398-123.788328 119.322619-123.788328l715.938786 0c65.907059 0 119.322619 55.429427 119.322619 123.788328l0 464.150202C989.292012 721.578226 934.664857 784.595468 867.265818 784.595468L867.265818 784.595468zM273.353227 373.638769c-32.94432 0-59.661821 27.715225-59.661821 61.898769 0 34.174334 26.716478 61.889559 59.661821 61.889559s59.661821-27.715225 59.661821-61.889559C333.015048 401.353994 306.297546 373.638769 273.353227 373.638769L273.353227 373.638769zM511.999488 373.638769c-32.94432 0-59.661821 27.715225-59.661821 61.898769 0 34.174334 26.735921 61.889559 59.661821 61.889559 32.94432 0 59.661821-27.715225 59.661821-61.889559C571.66131 401.353994 544.943808 373.638769 511.999488 373.638769L511.999488 373.638769zM750.64575 373.638769c-32.9259 0-59.661821 27.715225-59.661821 61.898769 0 34.174334 26.735921 61.889559 59.661821 61.889559 32.963763 0 59.661821-27.715225 59.661821-61.889559C810.307571 401.353994 783.609513 373.638769 750.64575 373.638769L750.64575 373.638769zM750.64575 373.638769"
                                    p-id="6488" fill="#afafaf"></path>
                            </svg>
                            <span class="c_a_num">{{item.pin}} 评论</span>
                        </div>
                        <div class="flex_center">
                            <svg t="1608635367240" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5229" width="22" height="22">
                                <path
                                    d="M512 192c156.448 0 296.021333 98.730667 418.410667 291.605333a52.938667 52.938667 0 0 1 0 56.789334C808.021333 733.269333 668.448 832 512 832c-156.448 0-296.021333-98.730667-418.410667-291.605333a52.938667 52.938667 0 0 1 0-56.789334C215.978667 290.730667 355.552 192 512 192z m0 128c-106.037333 0-192 85.962667-192 192s85.962667 192 192 192 192-85.962667 192-192-85.962667-192-192-192z m0 320c70.688 0 128-57.312 128-128s-57.312-128-128-128-128 57.312-128 128 57.312 128 128 128z"
                                    p-id="5230" fill="#afafaf"></path>
                            </svg>
                            <span class="c_a_num">{{item.view_count}} 浏览</span>
                        </div> -->
                    </div>
                </div>
            </div>
            <el-pagination background layout="prev, pager, next" :total="article_count" :current-page="page" @current-change="onCurrentChange"></el-pagination>
        </div>
        <div class="no_list" v-else>
            暂无文章
        </div>
    </div>
</template>

<script>
import {handleGetArticleList} from "@api/index"
export default {
    inject:['app'],
    data(){
        return{
            articleList: [],
            article_count: 0,
            page:1
        }
    },
    created(){
        
    },
    mounted(){
        // let {articleList = [],article_count = 0} = this.app;
        // if(article_count > 0){
        //     this.articleList = articleList;
        //     this.article_count = article_count;
        // }else{
            this.onGetArticleList()
        // }
    },
    methods:{
        onCurrentChange(evt){
            this.page = evt;
            this.onGetArticleList();
        },
        toDetail(data){
            let item = data;
            this.$router.push({path:'articleDetail',query:{article_id:item.id}})
        },
        onGetArticleList(){
            let datakey = {};
            datakey['page'] = this.page;
            datakey['user_type'] = 3
            handleGetArticleList(datakey).then(res=>{
                if(res.status == 0){
                    this.articleList = res.data.list;
                    this.article_count = res.data.count
                }else{
                    this.$message.error(res.msg)
                }
            }).catch(err=>{
                this.$message.error(err.msg)
                console.log(err)
            })
        }
    },
    computed:{

    }
    
}
</script>

<style lang="scss">
    .article_list{
        .el-pagination{
            margin: 20px auto;
        }
    }
    .cate_article_item {
        padding: 40px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-bottom: 1px solid #e6e6e6;
    }
    
    .cate_article_item:last-child {
        margin-bottom: 40px;
    }
    
    .c_a_img_box {
        height: 160px;
        width: 220px;
        margin-right: 20px;
    }
    
    .c_a_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .c_a_rg {
        height: 160px;
        flex: 1;
    }
    
    .c_a_title {
        line-height: 1.4;
        text-align: left;
    }
    
    .c_a_content {
        line-height: 20px;
        font-size: 14px;
        color: #a7a7a7;
        font-size: 14px;
        text-align:left;
        padding: 4px 0;
        word-break: break-all;
    }
    
    .cate_tag {
        background: #e5f2ff;
        color: #2478e3;
        padding: 0 10px;
        height: 26px;
        line-height: 26px;
        border-radius: 2px;
        font-size: 14px;
        margin-right: 10px;
    }
    
    .c_info_date {
        font-size: 14px;
        color: #afafaf;
        margin-right: 10px;
    }
    
    .c_a_num {
        font-size: 14px;
        color: #afafaf;
        margin-left: 4px;
    }


    .no_list{
        padding: 200px 0 20px;
        margin: 0 auto;
        text-align: center;
    }
</style>